class Keys {
    static getInstance() {
        if (!this.instance) {
            this.instance = new Keys();
        }
        return this.instance;
    }

    setAccessToken(token) {
        localStorage.setItem("keycloak.access_token", token);
    }

    getAccessToken() {
        return localStorage.getItem("keycloak.access_token");
    }

    setRefreshToken(token) {
        localStorage.setItem("keycloak.refresh_token", token);
    }

    getRefreshToken() {
        return localStorage.getItem("keycloak.refresh_token");
    }

    setIdToken(token) {
        localStorage.setItem("keycloak.id_token", token);
    }

    getIdToken() {
        return localStorage.getItem("keycloak.id_token");
    }
}
  
export default Keys;