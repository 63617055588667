import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { CognitoUserPool, CognitoUser, CognitoUserSession, CognitoIdToken, CognitoRefreshToken, CognitoAccessToken, } from 'amazon-cognito-identity-js';
import { configureAuthFromLoginMappingInSession } from './App';


export default function SsoPage() {
    const [searchParams] = useSearchParams();

    const apiBaseUrl = "https://us.api.mimecastcsp.com/dev/user-auth-api";
    // const apiBaseUrl = "http://localhost:3000/dev";

    useEffect(() => {
        const signIn = async (sessionId) => {
            // fetch tokens using sessionid
            const response = await fetch(apiBaseUrl + "/sso/token", {method: "POST", body: JSON.stringify({"session_id": sessionId})});
            if (!response.ok) {
                console.log("An error has occured: " + response.status);
                return;
            }

            const data = await response.json();
            console.log("data", JSON.stringify(data));

            const accessToken = data.access_token;
            const refreshToken = data.refresh_token;
            const idToken = data.id_token;
            const region = data.region;
            const userPoolId = data.user_pool_id;
            const clientId = data.client_id;

            const cognitoIdToken = new CognitoIdToken({
                IdToken: idToken,
            });
            const cognitoAccessToken = new CognitoAccessToken({
                AccessToken: accessToken,
            });
            const cognitoRefreshToken = new CognitoRefreshToken({
                RefreshToken: refreshToken,
            });
            let username = "";
            cognitoAccessToken.payload["cognito:groups"].forEach(group => {
                if (group.startsWith("email:")) {
                    username = group.substring(group.indexOf(":") + 1);
                    return;
                }
            });
            console.log("username", username);

            const user = new CognitoUser({
                Username: username,
                Pool: new CognitoUserPool({
                    UserPoolId: userPoolId,
                    ClientId: clientId,
                }),
            });

            window.sessionStorage.setItem("cognitoAuthConfig", JSON.stringify({
                region: region,
                user_pool_id: userPoolId,
                user_pool_client_id: clientId,
            }));
            configureAuthFromLoginMappingInSession();
    
            user.setSignInUserSession(new CognitoUserSession({
                AccessToken: cognitoAccessToken,
                IdToken: cognitoIdToken,
                RefreshToken: cognitoRefreshToken,
            }));

            window.location.href = "/dashboard";
        }

        let sessionId = searchParams.get("sessionid");
        signIn(sessionId);
    });

    return (
        <div className="App">
            <header className="App-header">
                SSO
            </header>
        </div>
    );
}
