import React from 'react';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Env } from '../env';

const LoginButton = () => {
    const clientId = "demo-ui";
    const redirectUri = Env.getUIBaseUrl() + "/pockeycloak/callback";
    const state = "STATE";
    const authorizeUrl = Env.getAuthServerBaseUrl() + "/realms/" + Env.getRealm() + "/protocol/openid-connect/auth?response_type=code&client_id=" + clientId + "&redirect_uri=" + redirectUri + "&scope=openid&state=" + state;
        
    return (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => window.location.href = authorizeUrl}
        >
            Login in
        </Button>
    );
}

export class LoginForm extends React.Component {
    render() {        
        return (
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{  
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>

                    <LoginButton/>
                </Box>
            </Container>
        );
    }
}