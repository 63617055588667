import React from 'react';
import KeyCloakKeys from './keys';

function Tokens() {
    return (
        <div>
            <div>Access token: <textarea style={{ width: 600, height: 200 }} value={KeyCloakKeys.getInstance().getAccessToken()}/></div>
            <div>Access token parsed: <textarea style={{ width: 600, height: 200 }} value={JSON.stringify(parseJwt(KeyCloakKeys.getInstance().getAccessToken()), null, 2)}/></div>
            <div>ID token: <textarea style={{ width: 600, height: 200 }} value={KeyCloakKeys.getInstance().getIdToken()}/></div>
            <div>ID token parsed: <textarea style={{ width: 600, height: 200 }} value={JSON.stringify(parseJwt(KeyCloakKeys.getInstance().getIdToken()), null, 2)}/></div>
        </div>
    );
}

function parseJwt(token) {
    console.log("parsing jwt", token);
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
}
  

export default Tokens;