import React from 'react';
import { Auth } from 'aws-amplify';
import { configureAuthFromLoginMappingInSession } from './App';
import * as msal from "@azure/msal-browser";
import QRCode from 'react-qr-code';

export default class SigninForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"stage": "discovery"};
        this.apiBaseUrl = "https://us.api.mimecastcsp.com/dev/user-auth-api";
        // this.apiBaseUrl = "http://localhost:3000/dev";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleTotpSubmit = this.handleTotpSubmit.bind(this);
        this.showMagicLink = this.showMagicLink.bind(this);
        this.handleMagicLinkSubmit = this.handleMagicLinkSubmit.bind(this);
    }

    componentDidMount = async (event) => {
        const urlParams = new URLSearchParams(window.location.search);
        this.setState({
            "redirect_uri": urlParams.get("redirect_uri") || "/dashboard", 
            "flow": urlParams.get("flow") || "code"
        });

        const session = await Auth.currentSession();
        if (session) {
            this.redirect(session);
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleDiscovery = async (event) => {
        event.preventDefault();
        const response = await fetch(this.apiBaseUrl + "/discovery?" + new URLSearchParams({
            email: this.state.email,
            redirect_uri: this.state.redirect_uri
        }));

        if (!response.ok) {
            console.log("An error has occured: " + response.status);
            return;
        }

        const data = await response.json();
        console.log("data", JSON.stringify(data));

        const mappings = data["login_mappings"];
        if (mappings.length == 0) {
            this.setState({"message": "Unknown user"});
            return;
        }

        let mapping;
        // prioritise m365, then local
        mappings.forEach(m => {
            if (m["type"] == "m365") {
                mapping = m;
            }
        });
        if (!mapping) {
            mapping = mappings[0];
        }
        console.log("preferred mapping", mapping);

        mapping["redirect_sign_in"] = new URL(window.location.href).origin + "/callback";
        mapping["flow"] = this.state.flow;

        //hack to force registration
        // mapping["mfa_registration"] = ["SOFTWARE_TOKEN_MFA"]

        if (mapping["mfa_registration"]) {
            this.setState({"registerMfaType": mapping["mfa_registration"][0]});
        } else {
            this.setState({"registerMfaType": undefined});
        }

        if (mapping["type"] == "local") {
            this.setState({"stage": "password", "message": ""});

            window.sessionStorage.setItem("cognitoAuthConfig", JSON.stringify(mapping));
            configureAuthFromLoginMappingInSession();
        } else if (mapping["type"] == "m365") {
            window.sessionStorage.setItem("cognitoAuthConfig", JSON.stringify(mapping));
            configureAuthFromLoginMappingInSession();
            Auth.federatedSignIn({provider: mapping["provider"], customState: btoa(JSON.stringify({"redirect_uri": this.getRedirectUrl(), "flow": this.state.flow}))});
        } else {
            console.log("Unrecognised mapping: " + mapping["type"]);
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await Auth.signIn(this.state.email, this.state.password);
            this.setState({"user": result});
            console.log("signin", JSON.stringify(result));

            if (result.challengeName) {
                if (result.challengeName == 'SOFTWARE_TOKEN_MFA') {
                    this.setState({"stage": "totp", "message": ""});
                } else if (result.challengeName == "NEW_PASSWORD_REQUIRED") {
                    this.setState({"stage": "changepassword", "message": ""});
                } else {
                    console.log("Unrecognised challenge: " + result.challengeName);
                    this.setState({"message": "Unrecognised challenge: " + result.challengeName});
                }
            } else {
                if (this.state.registerMfaType && this.state.registerMfaType == "TOTP") {
                    const totpSecret = await Auth.setupTOTP(this.state.user);
                    this.setState({"stage": "registertotp", "message": "", "totpsecret": totpSecret, "totpsecreturi": "otpauth://totp/CSP:"+ this.state.email + "?secret=" + totpSecret + "&issuer=CSP"});
                } else {
                    this.redirect();
                }
            }

        } catch (error) {
            console.log('error signing in', error);
            this.setState({"message": "Invalid username/password"});
            return;
        }
    }

    handleTotpSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await Auth.confirmSignIn(this.state.user, this.state.code, "SOFTWARE_TOKEN_MFA");
            console.log("totp", JSON.stringify(result));
        } catch (error) {
            console.log('error verifying totp', error);
            this.setState({"message": "Invalid code"});
            return;
        }
        this.redirect();
    }

    showMagicLink = async (event) => {
        event.preventDefault();
        this.setState({"stage": "magiclink"});
    }

    handleMagicLinkSubmit = async (event) => {
        event.preventDefault();
        let redirectUrl = new URL(this.state.redirect_uri, new URL(window.location.href).origin);
        const response = await fetch(this.apiBaseUrl + "/magiclink/send", {
            method: "PUT",
            body: JSON.stringify({
                "email": this.state.email, 
                "redirect_uri": redirectUrl
            })
        });

        if (!response.ok) {
            console.log("An error has occured: " + response.status);
            return;
        }

        let data = await response.json()
        console.log("send magic link response token", data.token);

        this.setState({"stage": "magiclinksent"});
    }

    getRedirectUrl(session) {
        let redirectUrl = new URL(this.state.redirect_uri, new URL(window.location.href).origin);
        if (this.state.flow == "token" && session) {
            redirectUrl.searchParams.set("access_token", session.getAccessToken().getJwtToken());
        }
        return redirectUrl;
    }

    redirect(session) {
        window.location.href = this.getRedirectUrl(session);
    }

    handleM365Submit = async (event) => {
        event.preventDefault();

        let mapping = {
            "type": "m365",
            "redirect_uri": "https://dev-mimecast-m365-1.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=token&scope=openid+profile+aws.cognito.signin.user.admin&redirect_uri=https%3A%2F%2Fttp.mimecast.com%2Fasdas&client_id=7oq3pgkcpptjg49co46dq728i9&identity_provider=M365-CUS1650377468916742834-CSP",
            "region": "us-east-1",
            "user_pool_id": "us-east-1_azaL3Wn1q",
            "user_pool_client_id": "7oq3pgkcpptjg49co46dq728i9",
            //note that provider is missing, it will be filled in later
            "domain": "dev-mimecast-m365-1.auth.us-east-1.amazoncognito.com",
            "scopes": [
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ]
        }
        mapping["redirect_sign_in"] = new URL(window.location.href).origin + "/callback";
        mapping["flow"] = this.state.flow;
        mapping["redirect"] = this.getRedirectUrl();

        window.sessionStorage.setItem("cognitoAuthConfig", JSON.stringify(mapping));

        const msalConfig = {
            auth: {
                clientId: "f499b9cf-e996-467c-8417-41a93a478a9e"
            }
        };
        
        const msalInstance = new msal.PublicClientApplication(msalConfig);
        try {
            const loginResponse = await msalInstance.loginRedirect();
            console.log(loginResponse);
        } catch (err) {
            console.log("error during msal redirect call", err);
        }
    }

    handleChangePasswordSubmit = async (event) => {
        event.preventDefault();
        try {
            if (this.state.newpassword != this.state.confirmpassword) {
                this.setState({"message": "Passwords don't match"});
                return;
            }

            const result = await Auth.completeNewPassword(this.state.user, this.state.newpassword);
            console.log("completenewpassword", JSON.stringify(result));
        } catch (error) {
            console.log('error completing new password', error);
            this.setState({"message": "An error occurred"});
            return;
        }

        this.redirect();
    }

    handleRegisterTotpSubmit = async (event) => {
        event.preventDefault();
        try {
            const result = await Auth.verifyTotpToken(this.state.user, this.state.code);
            console.log("verifytotptoken", JSON.stringify(result));

            await Auth.setPreferredMFA(this.state.user, 'TOTP');
        } catch (error) {
            console.log('error verifying totp token', error);
            this.setState({"message": "Invalid code"});
            return;
        }

        this.redirect();
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    {this.state.stage == "discovery" &&
                        <div>
                        <form onSubmit={this.handleDiscovery}>
                            <label>
                                Email:
                                <input type="email" name="email" value={this.state.email} onChange={this.handleChange} required/>
                            </label>
                            <br />
                            <input type="submit" value="Submit" />
                            <div style={{color: 'red'}}>{this.state.message}</div>
                        </form>
                        <button onClick={this.showMagicLink}>Magic link</button>
                        <br/>
                        <form onSubmit={this.handleM365Submit}>
                            <input type="submit" value="Login with M365" />
                        </form>
                    </div>
                    }
                    {this.state.stage == "password" &&
                        <div>
                            <form onSubmit={this.handleSubmit}>
                                <label>
                                    Email:
                                    <input type="email" name="email" value={this.state.email} onChange={this.handleChange} readOnly/>
                                </label>
                                <br />
                                <label>
                                    Password:
                                    <input type="password" name="password" value={this.state.password} onChange={this.handleChange} required/>
                                </label>
                                <br />
                                <input type="submit" value="Submit" />
                                <div style={{color: 'red'}}>{this.state.message}</div>
                            </form>
                            <br/>
                            <a href="/forgotpassword">Forgot password</a>
                        </div>
                    }
                    {this.state.stage == "totp" &&
                        <div>
                            <form onSubmit={this.handleTotpSubmit}>
                                <label>
                                    Code:
                                    <input type="text" name="code" value={this.state.code} onChange={this.handleChange} required/>
                                </label>
                                <br />
                                <input type="submit" value="Submit" />
                                <div style={{color: 'red'}}>{this.state.message}</div>
                            </form>
                        </div>
                    }
                    {this.state.stage == "magiclink" &&
                        <div>
                            <form onSubmit={this.handleMagicLinkSubmit}>
                                <label>
                                    Email:
                                    <input type="email" name="email" value={this.state.email} onChange={this.handleChange} required/>
                                </label>
                                <br />
                                <input type="submit" value="Send magic link" />
                                <div style={{color: 'red'}}>{this.state.message}</div>
                            </form>
                        </div>
                    }
                    {this.state.stage == "magiclinksent" &&
                        <div>
                            Login link has been sent, please check your email.
                        </div>
                    }
                    {this.state.stage == "changepassword" &&
                        <div>
                            <form onSubmit={this.handleChangePasswordSubmit}>
                                <p>You must change your password.</p>
                                <label>
                                    New Password:
                                    <input type="password" name="newpassword" value={this.state.newpassword} onChange={this.handleChange} required/>
                                </label>
                                <br />
                                <label>
                                    Confirm Password:
                                    <input type="password" name="confirmpassword" value={this.state.confirmpassword} onChange={this.handleChange} required/>
                                </label>
                                <br />
                                <input type="submit" value="Submit" />
                                <div style={{color: 'red'}}>{this.state.message}</div>
                            </form>
                        </div>
                    }
                    {this.state.stage == "registertotp" &&
                        <div>
                            <form onSubmit={this.handleRegisterTotpSubmit}>
                                <p>Register with your authenticator device.</p>
                                <label>
                                    Secret:
                                    <div>{this.state.totpsecret}</div>
                                </label>
                                <br />
                                <QRCode value={this.state.totpsecreturi}/>
                                <br/>
                                <label>
                                    Enter code:
                                    <input type="text" name="code" value={this.state.code} onChange={this.handleChange} required/>
                                </label>
                                <br/>
                                <input type="submit" value="Submit" />
                                <div style={{color: 'red'}}>{this.state.message}</div>
                            </form>
                        </div>
                    }

                </header>
            </div>
        );
    }
}