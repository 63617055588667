import React from 'react';

export default class PocIdentityLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {"stage": "password"};
        this.apiBaseUrl = "https://us.api.mimecastcsp.com/dev/user-auth-api";
        // this.apiBaseUrl = "http://localhost:3000/dev";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = async (event) => {
        const urlParams = new URLSearchParams(window.location.search);
        this.setState({
            "session": urlParams.get("session"), 
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const result = await fetch(this.apiBaseUrl + "/pocil/initiate", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "session": this.state.session,
                "username": this.state.username, 
                "password": this.state.password
            }),
        });
        console.log("result", result);

        if (!result.ok) {
            console.log('error signing in');
            this.setState({"message": "Invalid username/password"});
            return;
        }

        let data = await result.json();
        console.log("data", data);
        if (data.redirect) {
            window.location.href = data.redirect;
            return;
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    {this.state.stage == "password" &&
                        <div>
                            <form onSubmit={this.handleSubmit}>
                                <label>
                                    Username:
                                    <input type="email" name="username" value={this.state.username} onChange={this.handleChange} required/>
                                </label>
                                <br />
                                <label>
                                    Password:
                                    <input type="password" name="password" value={this.state.password} onChange={this.handleChange} required/>
                                </label>
                                <br />
                                <input type="submit" value="Submit" />
                                <div style={{color: 'red'}}>{this.state.message}</div>
                            </form>
                        </div>
                    }
                </header>
            </div>
        );
    }
}