import React from 'react';
import { Auth } from 'aws-amplify';

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    updateUser = async(event) => {
        console.log("updateUser");
        const session = await Auth.currentSession()

        const accesstoken = session.getAccessToken().getJwtToken();
        const decodedPayload = JSON.parse(atob(accesstoken.split(".")[1]));
        
        const accounts = [], roles = [];
        for (const i in decodedPayload["cognito:groups"]) {
            const group = decodedPayload["cognito:groups"][i];
            if (group.startsWith("acc:")) {
                accounts.push(group.substring(group.indexOf(":") + 1));
            }
            if (group.startsWith("role:")) {
                roles.push(group.substring(group.indexOf(":") + 1));
            }
        }

        this.setState({"loggedInUser": session.getIdToken().payload["email"], "accounts": accounts, "roles": roles});
    }

    callCCEndpoint = async (event) => {
        const session = await Auth.currentSession()
        if (!session.accessToken) {
            alert("User not logged in");
            return;
        }
        this.updateUser();

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + session.accessToken.jwtToken);
        myHeaders.append("Content-Type", "application/json");

        const accountId = this.state.accounts[0];
        var raw = '{"query":"query GetAccount($account_id: ID!) { account(account_id: $account_id) { account_id, name, status } }","variables":{"account_id": "' + accountId + '"}}';

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://us.api.mimecastcsp.com/dev/config-api/graphql", requestOptions)
            .then(response => response.text())
            .then(result => this.setState({ "info": result }))
            .catch(error => console.log('error', error));
    }

    handleSignOut = async (event) => {
        event.preventDefault();

        try {
            const result = await Auth.signOut();
            console.log(result);
        } catch (error) {
            console.log('error signing out', error);
        }

        window.location.href = "/"
    }

    componentDidMount() {
        this.updateUser();
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <div>Logged in as {this.state.loggedInUser}</div>
                    <div>Accounts: {this.state.accounts}</div>
                    <div>Roles: {this.state.roles}</div>
                    <textarea style={{ width: 400, height: 200 }} value={this.state.info} />
                    <br />
                    <button onClick={this.callCCEndpoint}>Call CC endpoint</button>
                    <br />
                    <button onClick={this.handleSignOut}>Log out</button>
                </header>
            </div>
        );
    }
}