import React from 'react';
import { Auth } from 'aws-amplify';

export default class ForgotPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.handleChange = this.handleChange.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleSendCode = this.handleSendCode.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleForgotPassword = async (event) => {
        event.preventDefault();

        if (!this.state.email) {
            this.setState({"message": "Invalid email"});
            return;
        }

        try {
            const result = await Auth.forgotPassword(this.state.email);
            console.log("forgotpassword", result);
            this.setState({"sentCode": true, "message": ""});
        } catch (error) {
            console.log('error with forgotpassword', error);
            this.setState({"sentCode": true, "message": ""});
            return;
        }
    }

    handleSendCode = async (event) => {
        event.preventDefault();

        if (!this.state.code) {
            this.setState({"message": "Invalid code/password"});
            return;
        }

        try {
            const result = await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.password);
            console.log("forgotpasswordsubmit", result);
            alert("Password changed successfully, redirecting to login page");
        } catch (error) {
            console.log('error with forgotpasswordsubmit', error);
            this.setState({"message": "Invalid code/password"});
            return;
        }

        window.location.href = "/";
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    {!this.state.sentCode &&
                        <form onSubmit={this.handleForgotPassword}>
                            <label>
                                Email:
                                <input type="email" name="email" value={this.state.email} onChange={this.handleChange} />
                            </label>
                            <br/>
                            <input type="submit" value="Send code in email" />
                            <div style={{color: 'red'}}>{this.state.message}</div>
                        </form>
                    }
                    {this.state.sentCode &&
                        <form onSubmit={this.handleSendCode}>
                            <label>
                                Code:
                                <input type="text" name="code" value={this.state.code} onChange={this.handleChange} />
                            </label>
                            <br/>
                            <label>
                                New password:
                                <input type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                            </label>
                            <br/>
                            <input type="submit" value="Submit" />
                            <div style={{color: 'red'}}>{this.state.message}</div>
                        </form>
                    }
            </header>
            </div>
        );
    }
}