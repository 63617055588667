const UI_BASE_URL = "https://demoui.mimecastidentity-dev.com";
// const UI_BASE_URL = "http://localhost:3000";
const AUTH_SERVER_URL = "https://100.64.100.247:8443";
// const AUTH_SERVER_URL = "http://localhost:8080";
const REALM = "poc";
// const REALM = "master";

export class Env {
    static getUIBaseUrl() {
        return UI_BASE_URL;
    }

    static getAuthServerBaseUrl() {
        return AUTH_SERVER_URL;
    }

    static getRealm() {
        return REALM;
    }
}