import React from 'react';

import './App.css';
import SigninForm from './signinform.js'
import { Auth } from 'aws-amplify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard';
import ForgotPasswordForm from './forgotpasswordform';
import CallbackPage from './callback';
import SsoPage from './sso';
import * as msal from "@azure/msal-browser";
import PocIdentityLoginForm from './pocidentityloginform';
import { LoginForm as KCLoginForm } from './pockeycloak/loginform';
import { CallbackPage as KCCallbackPage } from './pockeycloak/callbackpage';
import { Dashboard as KCDashboard } from './pockeycloak/dashboard';

window.LOG_LEVEL = 'DEBUG'

export const configureAuthFromLoginMappingInSession = () => {
  const mapping = JSON.parse(window.sessionStorage.getItem("cognitoAuthConfig"));
  
  const config = {
      region: mapping["region"],
      userPoolId: mapping["user_pool_id"],
      userPoolWebClientId: mapping["user_pool_client_id"],
      authenticationFlowType: 'USER_PASSWORD_AUTH',
  };

  if (mapping["type"] == "m365") {
      config["oauth"] = {
          domain: mapping["domain"],
          scope: mapping["scopes"],
          redirectSignIn: mapping["redirect_sign_in"],
          // redirectSignOut: new URL(window.location.href).origin,
          responseType: "code"
      };
  }

  Auth.configure(config);
}

if (window.sessionStorage.getItem("cognitoAuthConfig")) {
  configureAuthFromLoginMappingInSession();
}

const getCognitoProviderFromTenantId = (tenantId) => {
  // hardcoded list for now, could come from a UA endpoint possibly
  if (tenantId === "6811947a-1dbf-4d70-aa5b-745470cb183d") {
      return "M365-CUS1650377468916742834-CSP";
  }

  return null;
}

const msalConfig = {
  auth: {
      clientId: "f499b9cf-e996-467c-8417-41a93a478a9e",
  }
};
const msalInstance = new msal.PublicClientApplication(msalConfig);
msalInstance.handleRedirectPromise().then((tokenResponse) => {
  if (!tokenResponse) {
    return;
  }
  console.log("tokenResponse", tokenResponse);

  let tenantId = tokenResponse["tenantId"];
  console.log("tenantId", tenantId);

  let provider = getCognitoProviderFromTenantId(tenantId);
  if (!provider) {
      console.log("Unable to find provider for tenantId", tenantId);
      return;
  }

  configureAuthFromLoginMappingInSession();

  const mapping = JSON.parse(window.sessionStorage.getItem("cognitoAuthConfig"));
  Auth.federatedSignIn({provider: provider, customState: btoa(JSON.stringify({"redirect_uri": mapping["redirect"], "flow": "code"}))});

}).catch((error) => {
  // handle error, either in the library or coming back from the server
  console.log("error handling msal response", error);
});


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SigninForm />} />
        <Route path="pocil" element={<PocIdentityLoginForm />} />
        <Route path="sso" element={<SsoPage />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="forgotpassword" element={<ForgotPasswordForm />} />
        <Route path="callback" element={<CallbackPage />} />
        <Route path="pockeycloak" element={<KCLoginForm />} />
        <Route path="pockeycloak/callback" element={<KCCallbackPage />} />
        <Route path="pockeycloak/dashboard" element={<KCDashboard />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
