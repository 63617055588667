import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import KeyCloakKeys from './keys';
import { Env } from '../env';

export function CallbackPage() {
    const navigate = useNavigate();

    const fetchToken = async () => {
        const clientId = "demo-ui";
        const redirectUri = Env.getUIBaseUrl() + "/pockeycloak/callback";

        const queryParams = new URLSearchParams(window.location.href.substring(window.location.href.indexOf("?")));
        const state = queryParams.get("state");
        const code = queryParams.get("code");
        console.log("code", code);

        const url = Env.getAuthServerBaseUrl() + "/realms/" + Env.getRealm() + "/protocol/openid-connect/token"

        const response = await fetch(url, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: new URLSearchParams({
                "grant_type": "authorization_code",
                "code": code,
                "client_id": clientId,
                "redirect_uri": redirectUri,
                "state": state,
            })
        });
        console.log("response", response);

        let responsePayload = await response.json();
        console.log("response payload", responsePayload);

        KeyCloakKeys.getInstance().setAccessToken(responsePayload.access_token);
        KeyCloakKeys.getInstance().setRefreshToken(responsePayload.refresh_token);
        KeyCloakKeys.getInstance().setIdToken(responsePayload.id_token);
        
        navigate("/pockeycloak/dashboard");
    }

    useEffect(() => {
        fetchToken();
    });

    return (
        <div className="App">
            Redirecting...
        </div>
    );
}
