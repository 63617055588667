import { Auth, Hub } from 'aws-amplify';
import React, { useEffect } from 'react';

export default function CallbackPage() {
    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "customOAuthState":
                    const d = JSON.parse(atob(data));
                    let redirectUrl = d["redirect_uri"];
                    if (d["flow"] == "token") {
                        Auth.currentSession().then(session => {
                            redirectUrl += "?accesstoken=" + session.getAccessToken().getJwtToken();
                            window.location.href = redirectUrl;
                        });
                    } else {
                        window.location.href = redirectUrl;
                    }
                    
                    break;
                default:
                    break;
            }
        });
    });

    return (
        <div className="App">
            <header className="App-header">
                Redirecting...
            </header>
        </div>
    );
}
